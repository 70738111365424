<template>
    <svg
        viewBox="0 0 1930 209"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xml:space="preserve"
        xmlns:serif="http://www.serif.com/"
        style="fill-rule: evenodd; clip-rule: evenodd; stroke-miterlimit: 2">
        <g transform="matrix(4.16667,0,0,4.16667,0,0)">
            <path
                d="M397.513,2.389L384.594,2.389L376.031,23.821L381.907,38.932L397.513,2.389ZM379.279,45.085L362.456,2.389L349.537,2.389L368.764,48.12L377.983,48.12L379.279,45.085ZM323.573,48.12L335.46,48.12L335.46,2.388L323.573,2.388L323.573,48.12ZM309.029,48.12L297.407,30.902C303.897,29.75 304.662,22.768 304.662,16.46C304.662,6.695 297.809,2.389 288.772,2.389L271,2.389L271,48.12L282.888,48.12L282.888,11.487L289.186,11.487C293.068,11.487 293.296,15.463 293.296,18.501C293.296,23.059 290.661,24.97 287.06,25.103L287.06,30.696L296.982,48.12L309.029,48.12ZM254.243,25.254C254.243,12.396 243.75,2.389 231.013,2.389L214.151,2.389L214.151,48.12L225.974,48.12L226.039,12.457L228.829,12.457C236.471,12.457 241.87,17.067 241.87,25.254C241.87,33.34 236.875,37.275 230.654,37.939L230.654,48.12L231.013,48.12C243.689,48.12 254.243,38.113 254.243,25.254ZM193.78,48.12L199.905,48.12L178.071,0.448L156.236,48.12L162.362,48.12L178.071,12.942L186.198,31.441L175.108,31.441L172.758,36.657L188.563,36.657L193.78,48.12ZM149.656,2.389L122.241,2.389L122.241,7.605L149.656,7.605L149.656,2.389ZM88.477,13.246C88.477,9.243 92.48,6.756 96.118,6.756C99.576,6.756 101.76,8.212 103.458,11.062L108.007,8.333C105.52,3.905 101.335,1.419 96.24,1.419C89.325,1.419 82.775,5.846 82.775,13.246C82.775,19.614 87.506,22.586 92.723,24.83L95.877,26.164C99.818,27.802 103.762,29.924 103.762,34.837C103.762,39.629 100.244,43.754 95.331,43.754C90.418,43.754 86.415,40.114 86.476,35.08L80.713,36.292C81.684,43.631 87.445,49.09 94.845,49.09C103.034,49.09 109.463,43.147 109.463,34.837C109.463,27.196 104.611,23.617 98.121,21.07L94.845,19.796C91.995,18.704 88.477,16.824 88.477,13.246ZM64.544,35.295L64.544,2.389L58.843,2.389L58.843,29.392L64.544,35.295ZM24.515,0.206L24.515,48.12L30.215,48.12L30.215,14.034L64.544,50L64.544,42.033L24.515,0.206ZM0,48.12L5.7,48.12L5.7,2.388L0,2.388L0,48.12ZM138.8,12.206L133.098,12.206L133.098,48.121L138.8,48.121L138.8,12.206Z" />
            <path
                d="M409.439,21.815L406.353,29.005L439.217,29.005L445.196,21.815L409.439,21.815Z" />
            <path
                d="M409.439,21.815L406.353,29.005L439.217,29.005L445.196,21.815L409.439,21.815Z" />
            <path
                d="M409.439,21.815L406.353,29.005L439.217,29.005L445.196,21.815L409.439,21.815Z" />
            <path
                d="M409.439,21.815L406.353,29.005L439.217,29.005L445.196,21.815L409.439,21.815Z" />
            <path
                d="M398.625,47.008L424.244,47.008L430.269,39.763L401.736,39.763L398.625,47.008Z" />
            <path
                d="M398.625,47.008L424.244,47.008L430.269,39.763L401.736,39.763L398.625,47.008Z" />
            <path
                d="M398.625,47.008L424.244,47.008L430.269,39.763L401.736,39.763L398.625,47.008Z" />
            <path
                d="M398.625,47.008L424.244,47.008L430.269,39.763L401.736,39.763L398.625,47.008Z" />
            <path d="M417.189,3.758L414.081,11.002L454.188,11.002L460.213,3.758L417.189,3.758Z" />
            <path d="M417.189,3.758L414.081,11.002L454.188,11.002L460.213,3.758L417.189,3.758Z" />
            <path d="M417.189,3.758L414.081,11.002L454.188,11.002L460.213,3.758L417.189,3.758Z" />
            <path d="M417.189,3.758L414.081,11.002L454.188,11.002L460.213,3.758L417.189,3.758Z" />
        </g>
    </svg>
</template>
