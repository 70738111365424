<template>
    <svg viewBox="0 0 35 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
            <path d="M1 16.5h33v8H1v-8z" fill="#FFCE00" />
            <path d="M1 .5h33v8H1v-8z" fill="#000" />
            <path d="M1 8.5h33v8H1v-8z" fill="#D00" />
        </g>
        <path stroke="#000" stroke-linejoin="round" d="M1 .5h33v24H1z" />
        <defs>
            <clipPath id="clip0">
                <path fill="#fff" d="M1 .5h33v24H1z" />
            </clipPath>
        </defs>
    </svg>
</template>
