<template>
    <svg viewBox="0 0 35 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
            <path d="M34 24.5H1V.5h33v24z" fill="#fff" />
            <path d="M34 24.5H1v-8h33v8zm0-15.995H1v-8h33v8z" fill="#ED2939" />
        </g>
        <path stroke="#000" stroke-linejoin="round" d="M1 .5h33v24H1z" />
        <defs>
            <clipPath id="clip0"><path fill="#fff" d="M1 .5h33v24H1z" /></clipPath>
        </defs>
    </svg>
</template>
