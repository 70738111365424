<template>
    <svg viewBox="0 0 35 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_301_293)">
            <path d="M34 0.5H1V24.5H34V0.5Z" fill="#ED2939" />
            <path d="M23 0.5H1V24.5H23V0.5Z" fill="white" />
            <path d="M12 0.5H1V24.5H12V0.5Z" fill="#002395" />
        </g>
        <rect x="1" y="0.5" width="33" height="24" stroke="black" stroke-linejoin="round" />
        <defs>
            <clipPath id="clip0_301_293">
                <rect x="1" y="0.5" width="33" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
