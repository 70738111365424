<template>
    <svg viewBox="0 0 35 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)" fill-rule="evenodd" clip-rule="evenodd">
            <path d="M1 24.5h33V.5H1v24z" fill="#D52B1E" />
            <path d="M9.766 14.75h15.468v-4.5H9.766v4.5z" fill="#fff" />
            <path d="M15.18 20h4.64V5h-4.64v15z" fill="#fff" />
        </g>
        <path stroke="#000" stroke-linejoin="round" d="M1 24.5h33V.5H1z" />
        <defs>
            <clipPath id="clip0">
                <path transform="matrix(1 0 0 -1 1 24.5)" fill="#fff" d="M0 0h33v24H0z" />
            </clipPath>
        </defs>
    </svg>
</template>
